<template>
  <div class="subscribeBox">
    <!-- <div class="bigtop">
            {{
                styleFlag
                ? '你授权的小程序，符合第三方一键配置规则，请点击一键配置，完成下面所有订阅消息配置'
                    : '请你登录微信公众号平台，增加小程序类目，为：商家自营 > 服装/鞋/箱包；配置完成后，可以一键配置完成。'
            }}
            <el-popover placement="top" width="230" trigger="hover" content="该类目为微信第三方指定类目，不添加，订阅消息功能无法使用">
                <i style="color: #417be6; cursor: pointer" v-if="!styleFlag" slot="reference"
                    class="el-icon-question"></i>
            </el-popover>
            <p class="looktips" @click="lookexamplesMethond(0)">查看设置教程</p>
            <el-button :type="styleFlag ? 'primary' : ''" :disabled="!styleFlag" @click="oneSetting">一键设置</el-button>
        </div> -->
    <div class="bigBot">
      <div class="bigBotLeft">
        <img src="@/assets/image/shopDingYue.png" alt="" />
      </div>
      <div class="bigBotRight">
        <div class="chooseBox">
          <div :class="activeType == 1 ? 'isActive' : ''" @click="activeType = 1">商家通知</div>
          <div :class="activeType == 2 ? 'isActive' : ''" @click="activeType = 2">聚好玩通知</div>
        </div>
        <div class="firstBox" v-if="activeType == 1">
          <div class="formItem">
            <div class="title">
              <p>对账通知：</p>
            </div>
            <el-input v-model="shop.bill"></el-input>
            <p class="looktips" @click="lookexamplesMethond(1)">查看配置示例</p>
          </div>
          <div class="formItem">
            <div class="title">
              <p>订单发货通知：</p>
            </div>
            <el-input v-model="shop.order"></el-input>
            <p class="looktips" @click="lookexamplesMethond(1)">查看配置示例</p>
          </div>
          <div class="formItem">
            <div class="title">
              <p>售后申请通知：</p>
            </div>
            <el-input v-model="shop.refund"></el-input>
            <p class="looktips" @click="lookexamplesMethond(2)">查看配置示例</p>
          </div>
          <div class="formItem">
            <div class="title">
              <p>商家openID：</p>
            </div>
            <el-input style="width: 330px" v-model="shop.openID" disabled></el-input>
            <el-button type="primary" @click="dialogVisible = true">选择</el-button>
          </div>
        </div>
        <div class="firstBox" v-if="activeType == 2">
          <div class="formItem">
            <div class="title">
              <p>开店审核通过提醒：</p>
            </div>
            <el-input v-model="twitter.successfullyOpened"></el-input>
          </div>
          <div class="formItem">
            <div class="title">
              <p>审核驳回通知：</p>
            </div>
            <el-input v-model="twitter.approvalRejection"></el-input>
          </div>
          <div class="formItem">
            <div class="title">
              <p>分销成功提醒：</p>
            </div>
            <el-input v-model="twitter.successfulDistribution"></el-input>
          </div>
          <div class="formItem">
            <div class="title">
              <p>合伙人申请成功：</p>
            </div>
            <el-input v-model="twitter.successfulPartner"></el-input>
          </div>
          <div class="formItem">
            <div class="title">
              <p>提现成功：</p>
            </div>
            <el-input v-model="twitter.successfulWithdrawal"></el-input>
          </div>
          <div class="formItem">
            <div class="title">
              <p>合伙人申请成功：</p>
            </div>
            <el-input v-model="twitter.successfulRegiment"></el-input>
          </div>
          <div class="formItem">
            <div class="title">
              <p>店铺收款成功：</p>
            </div>
            <el-input v-model="twitter.successfulCollection"></el-input>
          </div>
          <div class="formItem">
            <div class="title">
              <p>店铺退款成功：</p>
            </div>
            <el-input v-model="twitter.successfulRefund"></el-input>
          </div>
          <div class="formItem">
            <div class="title">
              <p>提现失败：</p>
            </div>
            <el-input v-model="twitter.FailWithdrawal"></el-input>
          </div>
          <div class="formItem">
            <div class="title">
              <p>商家审核提醒：</p>
            </div>
            <el-input v-model="twitter.AuditReminder"></el-input>
          </div>
          <div class="formItem">
            <div class="title">
              <p>员工加入成功提醒：</p>
            </div>
            <el-input v-model="twitter.successfulStaff"></el-input>
          </div>
          <div class="formItem">
            <div class="title">
              <p>组局成功：</p>
            </div>
            <el-input v-model="twitter.zujuSuccess"></el-input>
          </div>
          <div class="formItem">
            <div class="title">
              <p>组局失败：</p>
            </div>
            <el-input v-model="twitter.zujuFail"></el-input>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="选择商家" :visible.sync="dialogVisible" width="52%">
      <ListComponents v-if="dialogVisible" :styleMethon="5" @getListItem="getListItem"></ListComponents>
    </el-dialog>
    <!-- <el-button type="primary" class="last" @click="preservation">保存</el-button> -->
    <Preservation @preservation="preservation"></Preservation>
    <LookExamples ref="LookExamples" v-show="lookexamples" :showIndex="showIndex" @hidden="hiddenexamples"></LookExamples>
  </div>
</template>

<script>
import LookExamples from '@/components/previewImg';
import Preservation from '@/components/preservation';
import ListComponents from '@/components/listComponents';
export default {
  components: {
    LookExamples,
    Preservation,
    ListComponents,
  },
  data() {
    return {
      activeType: 1,
      id: -1,
      shop: {},
      user: {},
      twitter: {},
      isHave: false,
      lookexamples: false,
      showIndex: -1,
      dialogVisible: false,
      // 是否夫否一键配置的类别需求
      styleFlag: true,
    };
  },
  methods: {
    // 获取小程序类别
    getAppStyle() {
      this.$axios.post(this.$api.set.officialAccountSet.getCategory, {}).then(res => {
        if (res.code == 0) {
          // if (res.result.data.findIndex(item => item.name == '服装/鞋/箱包') != -1) {
          //   this.styleFlag = true;
          // } else {
          //   this.styleFlag = false;
          // }
        } else {
          this.styleFlag = false;
          this.$message.warning('请将小程序基础设置配置完整，并配置 商家自营 > 服装/鞋/箱包 类别后，可以一键设置订阅消息');
        }
      });
    },
    // 一键设置
    oneSetting() {
      let that = this;
      this.$axios.post(this.$api.set.officialAccountSet.addTemplate, { type: 15 }).then(res => {
        if (res.code == 0) {
          this.shop = res.result || {};
        } else {
          this.$message.error(`${res.msg}`);
        }
      });
    },
    // 选中商家id
    getListItem(item) {
      this.shop.openID = item.mini_openid;
      this.dialogVisible = false;
    },
    // 开启教程设置
    lookexamplesMethond(index) {
      this.$refs.LookExamples.getImgList(index);
      this.lookexamples = true;
    },
    // 关闭教程设置
    hiddenexamples() {
      this.lookexamples = false;
    },
    // 获取页面信息
    getConfig() {
      this.$axios
        .post(this.$api.set.Settinginfo, {
          type: 15,
        })
        .then(res => {
          if (res.code == 0) {
            if (res.result.info == '') {
              return;
            }
            this.isHave = true;
            if (res.result.info.sets) {
              this.shop = res.result.info.sets.store || {};
              this.twitter = res.result.info.sets.twitter;
            }
            this.id = res.result.info.id;
          }
        });
    },
    // 保存
    preservation() {
      let that = this;
      let url = '';
      let obj = {};
      if (this.isHave) {
        url = this.$api.set.Settingedit;
        obj = {
          id: this.id,
          type: 15,
          sets: {
            store: this.shop,
            users: this.user,
            twitter: this.twitter,
          },
        };
      } else {
        url = this.$api.set.Settingadd;
        obj = {
          type: 15,
          sets: {
            store: this.shop,
            users: this.user,
            twitter: this.twitter,
          },
        };
      }
      this.$axios.post(url, obj).then(res => {
        if (res.code == 0) {
          that.$message({
            message: '保存成功',
            type: 'success',
          });
          that.isHave = true;
        }
      });
    },
  },
  created() {
    this.getConfig();
    // this.getAppStyle();
  },
};
</script>

<style lang="less" scoped>
.subscribeBox {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px 20px 50px 20px;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }

  .bigtop {
    height: 64px;
    background: #ebf2ff;
    border: 1px solid #81aeff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding-left: 15px;
    margin-bottom: 18px;

    p {
      margin: 0 220px 0 20px;
      cursor: pointer;
      color: #1467ff;
    }
  }

  .bigBot {
    display: flex;

    .bigBotLeft {
      width: 375px;
    }

    .bigBotRight {
      width: calc(100% - 410px);
      margin-left: 35px;

      .chooseBox {
        display: flex;
        margin-bottom: 20px;

        & > div {
          width: 90px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #bfbfbf;
          border-radius: 0px 2px 2px 0px;
          text-align: center;
          line-height: 32px;
          font-size: 14px;
          cursor: pointer;
        }

        .isActive {
          color: #1467ff;
          background: #ebf2ff;
          border: 1px solid #1467ff;
        }
      }
    }
  }
}

.firstBox {
  box-sizing: border-box;
  padding-bottom: 20px;

  .topTitle {
    display: flex;
    margin: 20px 0;
    align-items: center;

    h3 {
      width: 190px;
    }
  }

  & > h3 {
    margin-bottom: 20px;
  }
}

.formItem {
  display: flex;
  font-size: 14px;
  color: '#666666';
  margin-bottom: 15px;

  .isOpen {
    width: 690px;
    line-height: 40px;
    color: #9a9a9a;
    white-space: nowrap;
  }

  .title {
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
    flex-shrink: 0;
  }

  .edition {
    padding-top: 3px;
    box-sizing: border-box;
  }

  .looktips {
    color: #477eff;
    cursor: pointer;
    font-size: 14px;
    line-height: 40px;
    margin-left: 20px;
    white-space: nowrap;
  }
}

.el-input {
  max-width: 400px;
}

.last {
  margin-top: 20px;
  margin-left: 200px;
}
</style>
